import React from "react";
import {
  SiteNavigationElementSchema,
  FoodEstablishmentSchema,
  SiteNavigation,
  HoursOfOperation,
  RecaptchaProvider,
  Footer,
  Location,
  SocialMedia,
  SiteMap,
  CopyrightLabel,
  OutboundLink,
  FishermanBanner,
} from "@bluefin/components";
import { Header } from "semantic-ui-react";
import { graphql, StaticQuery } from "gatsby";

import {
  getComponentFiles,
  filterRegularLinks,
  filterButtonLinks,
} from "../utils/utils";
import "semantic-ui-less/semantic.less";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            title
            pageType
            url
            navigationPriority
            groupName
            props
          }
        }
        fishermanBusiness {
          name
          type
          menu {
            schedules {
              availableDays
              categories
              _id
              name
            }
            categories {
              items
              name
              _id
            }
            items {
              modifierSets
              description
              _id
              name
              variations {
                _id
                menuItem
                name
                order
                price
              }
            }
            modifierSets {
              _id
              minAllowed
              modifiers
              name
            }
            modifiers {
              _id
              name
              price
            }
          }
          primaryLocation {
            timezone
            hours {
              day
              open
              close
              label
            }
            phoneNumber
            street
            city
            state
            zipCode
          }
          socialMedia {
            link
            type
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
          components {
            fastId
            componentIdentifier
            order
            files {
              file
              gatsbyFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const {
        allFishermanBusinessWebsitePage,
        fishermanBusiness,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
      } = data;

      return (
        <React.Fragment>
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://www.maidriterolla.com"}
          />
          <FoodEstablishmentSchema
            name={fishermanBusiness.name}
            siteUrl={"https://www.maidriterolla.com"}
            businessType={fishermanBusiness.type}
            address={allFishermanBusinessLocation.nodes}
            menu={fishermanBusiness.menu}
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
          />
          <div className={"site-container"}>
            <div>
              <SiteNavigation widths={3}>
                <SiteNavigation.Menu position={"left"}>
                  <SiteNavigation.Links
                    links={filterRegularLinks({
                      links: allFishermanBusinessWebsitePage.nodes,
                    })}
                  />
                </SiteNavigation.Menu>
                <SiteNavigation.Logo
                  title={<Header as={"h3"}>{fishermanBusiness.name}</Header>}
                />
                <SiteNavigation.Menu position={"right"}>
                  <SiteNavigation.Item position={"right"}>
                    <HoursOfOperation
                      timezone={fishermanBusiness.primaryLocation.timezone}
                      hours={fishermanBusiness.primaryLocation.hours}
                      displayOption={"dailyWithPopup"}
                      displayOpenClosedStatus={true}
                    />
                  </SiteNavigation.Item>
                  <SiteNavigation.Links
                    links={filterButtonLinks({
                      links: allFishermanBusinessWebsitePage.nodes,
                    })}
                  />
                </SiteNavigation.Menu>
              </SiteNavigation>
            </div>
            <RecaptchaProvider>
              <div className={"page-container"}>{children}</div>
              <Footer
                backgroundColor={"primary"}
                horizontalAlign={"center"}
                verticalAlign={"middle"}
                columns={[
                  <Location
                    businessName={fishermanBusiness.name}
                    phone={fishermanBusiness.primaryLocation.phoneNumber}
                    address={{
                      street: fishermanBusiness.primaryLocation.street,
                      city: fishermanBusiness.primaryLocation.city,
                      state: fishermanBusiness.primaryLocation.state,
                      zip: fishermanBusiness.primaryLocation.zipCode,
                    }}
                  />,
                  <div>
                    <SocialMedia
                      socialMediaValues={fishermanBusiness.socialMedia}
                      buttonType={"circle"}
                      centerHeader={false}
                      colors={"secondary"}
                      groupButtons={false}
                      groupVertically={false}
                      header={
                        <Header as={"div"} className={"h6"}>
                          Social
                        </Header>
                      }
                      inverted={false}
                      labelPosition={"free"}
                      showLabels={false}
                    />
                    <SiteMap
                      links={allFishermanBusinessWebsitePage.nodes}
                      horizontal={true}
                      bulleted={false}
                    />
                    <CopyrightLabel
                      company={fishermanBusiness.name}
                      phrase={"All Rights Reserved"}
                      privacyPolicyLink={
                        <OutboundLink
                          to={
                            "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                          }
                          label={"Privacy Policy"}
                          eventLabel={"copyright-privacy-policy"}
                        />
                      }
                    />
                  </div>,
                  <HoursOfOperation
                    hours={fishermanBusiness.primaryLocation.hours}
                    displayOption={"grouped"}
                  />,
                ]}
              />
            </RecaptchaProvider>
          </div>
          <FishermanBanner />
        </React.Fragment>
      );
    }}
  />
);
